import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

export const HorizontalLine = ({
    thickness = 1,
    bgColor = 'lightgray',
    width = '100%',
    mt = 24,
    mb = 24,
    ml = 0,
    mr = 0,
    gradient = ''
}: {
    thickness?: number
    bgColor?: string
    width?: string
    mt?: number
    mb?: number
    ml?: number
    mr?: number
    gradient?: string
}): ReactNode => (
    <hr
        style={{
            backgroundColor: gradient || bgColor,
            height: `${thickness}px`,
            width: width,
            alignSelf: 'start',
            border: 1,
            marginTop: mt,
            marginBottom: mb,
            marginRight: mr,
            marginLeft: ml,
            display: 'inline'
        }}
    />
)

export const SmallCircle = ({ left }: { left: number }): ReactNode => {
    const calcLeft = left > 0 ? left : 0
    const calcMarginLeft = left > 0 ? -left : left
    return (
        <Box
            style={{
                position: 'relative',
                left: `${calcLeft}px`,
                marginLeft: `${calcMarginLeft}px`,
                color: 'lightgray',
                backgroundColor: 'white',
                border: 'solid 1px',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                display: 'inline',
                verticalAlign: 'middle',
                marginTop: 19,
                zIndex: 4
            }}></Box>
    )
}

export const SmallCircle2 = ({ left, mt = 0, mb = 0, width = 10, height = 18 }: { left: number; mt?: number; mb?: number; width?: number; height?: number }): ReactNode => {
    return (
        <Box
            style={{
                position: 'relative',
                left: `${left}px`,
                marginLeft: 0,
                color: 'lightgray',
                backgroundColor: 'white',
                border: 'solid 1px',
                width: `${width}px`,
                height: `${height}px`,
                borderRadius: '50%',
                display: 'inline',
                verticalAlign: 'middle',
                marginTop: mt,
                marginBottom: mb,
                zIndex: 4
            }}></Box>
    )
}

export const HorizontalRate = ({
    rate,
    maxValue,
    width,
    alignRight = false,
    left = 0,
    mt = 0,
    height = 5,
    lineColor = 'black'
}: {
    rate: number
    maxValue: number
    width: number
    alignRight?: boolean
    left?: number
    mt?: number
    height?: number
    lineColor?: string
}): ReactNode => (
    <Box
        display='flex'
        flexDirection={alignRight ? 'row-reverse' : 'row'}
        style={{
            width: `${width}px`,
            height: `${height}px`,
            left: `${left}px`,
            backgroundColor: 'lightgray',
            alignContent: 'center',
            marginTop: `${mt}px`,
            position: 'relative'
        }}>
        <Box
            style={{
                width: `${(rate / maxValue) * 100}%`,
                height: '100%',
                backgroundColor: lineColor
            }}></Box>
    </Box>
)
