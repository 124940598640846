import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import CirclePicker from '@uiw/react-color-circle'
import React, { useState } from 'react'
import { reactColors } from '../models/HTMLColors'

const SelectColorComponent = ({ value, handleChange }: { value: string; handleChange: (newValue: string) => void }): JSX.Element => {
    const [openColor, setOpenColor] = useState(false)

    return (
        <Box
            display='flex'
            alignItems='center'>
            <Dialog
                open={openColor}
                onClose={() => {
                    setOpenColor(false)
                }}
                draggable={true}
                scroll={undefined}>
                <DialogTitle>
                    <Typography>Select Color</Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpenColor(false)}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Box
                    border={1}
                    height='max-content'
                    padding={1}
                    minWidth='180px'>
                    <Button onClick={() => handleChange('#000000')}>Black</Button>
                    <Button onClick={() => handleChange('#FFFFFF')}>White</Button>
                    <CirclePicker
                        color={value as string}
                        colors={reactColors}
                        onChange={(color) => handleChange(color.hex)}
                        //circleSize={12}
                        //circleSpacing={6}
                        //width='170px'
                    />
                </Box>
            </Dialog>
            <Box
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: value as string,
                    marginRight: 1,
                    marginLeft: 1,
                    verticalAlign: 'middle',
                    border: '1px solid #ccc',
                    display: 'inline-block'
                }}
                onClick={() => setOpenColor(true)}
            />
        </Box>
    )
}

export default SelectColorComponent
