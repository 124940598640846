import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useState } from 'react'
import EditModal from '../../EditModal/EditModal'

const SocialMediaLinks: React.FC = () => {
    const euContext = useContext(EmploymentUniverseContext)
    const { linkedIn, twitter, instagram, skype } = euContext.resume.personalInfo

    const socialLinks = [
        { icon: 'linkedin', label: 'LinkedIn Username', platform: 'LinkedIn', value: linkedIn, name: 'personalInfo.linkedIn' },
        { icon: 'twitter', label: 'Twitter Username', platform: 'Twitter', value: twitter, name: 'personalInfo.twitter' },
        { icon: 'instagram', label: 'Instagram Username', platform: 'Instagram', value: instagram, name: 'personalInfo.instagram' },
        { icon: 'skype', label: 'Skype Username', platform: 'Skype', value: skype, name: 'personalInfo.skype' }
    ]

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentEditField, setCurrentEditField] = useState<string | null>(null)

    const handleOpenModal = (name: string) => {
        setCurrentEditField(name)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentEditField(null)
    }

    const getIcon = (icon: string) => {
        switch (icon) {
            case 'linkedin':
                return <i className='fab fa-linkedin'></i>
            case 'twitter':
                return <i className='fab fa-twitter'></i>
            case 'instagram':
                return <i className='fab fa-instagram'></i>
            case 'skype':
                return <i className='fab fa-skype'></i>
            default:
                return null
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {socialLinks.map((link, index) => (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 50,
                        gap: '65px',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleOpenModal(link.name)}>
                    <div
                        style={{
                            fontSize: '24px',
                            color: '#000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        {getIcon(link.icon)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ fontSize: '13px', color: '#888', fontStyle: 'italic' }}>{link.label}</div>
                        <div style={{ fontSize: '12px', color: '#888', fontStyle: 'italic' }}>{link.value || link.platform}</div>
                    </div>
                </div>
            ))}
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={currentEditField ? [currentEditField] : []}
                hideTitle={true}
            />
        </div>
    )
}

export default SocialMediaLinks
