import { Box, Grid2, Stack } from '@mui/material'
import React from 'react'
import ContactInfoComponent from '../components/resume/contact/ContactInfoComponent'
import EducationSummaryComponent from '../components/resume/educations/EducationSummaryComponent'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import PictureDodecagon from '../components/resume/picture/PictureDodecagon'
import ReferencesComponent from '../components/resume/references/ReferencesComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import SocialMediaLinks from '../components/resume/socialMedia/SocialMediaLinks'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'
import { AllDefaultCards, defaultCard } from '../utils/Util'

const Resume3 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume3.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })
    return (
        <Box
            sx={{
                width: '800px',
                margin: 'auto',
                border: '10px solid transparent',
                borderImage: 'linear-gradient(to bottom, #1A2980, #26D0CE) 1',
                backgroundColor: '#fff'
            }}>
            <Grid2
                sx={{ width: '100%' }}
                container>
                <Grid2 sx={{ width: '100%' }}>
                    <Stack
                        sx={{ width: '100%' }}
                        spacing={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                paddingRight: '50px',
                                paddingLeft: '50px',
                                paddingTop: '30px'
                            }}>
                            <PictureDodecagon
                                picWidth={170}
                                picHeight={170}
                                picAlign='center'
                                picMT={0}
                            />
                            <Box>
                                <NameVerticleComponent
                                    sx={{ letterSpacing: '6px', textTransform: 'uppercase' }}
                                    mt={3}
                                    mb={3}
                                />
                                <TitleComponent />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}>
                            <Stack
                                sx={{ width: '40%' }}
                                spacing={3}>
                                <ContactInfoComponent />
                                <SocialMediaLinks />
                                <EducationSummaryComponent />
                                <ReferencesComponent />
                            </Stack>

                            <Box
                                sx={{
                                    position: 'relative',
                                    minHeight: '500px',
                                    width: '2px',
                                    background: 'linear-gradient(to bottom, rgba(26, 41, 128, 1), rgba(38, 208, 206, 0))',
                                    margin: '0 auto'
                                }}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '5px',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'rgb(81, 127, 185)',
                                        borderRadius: '50%'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '183px',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'rgb(81, 127, 185)',
                                        borderRadius: '50%'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '673px',
                                        left: '50%',
                                        transform: 'translate(-50%, 50%)',
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'rgb(81, 127, 185)',
                                        borderRadius: '50%'
                                    }}
                                />
                            </Box>

                            <Stack
                                sx={{ width: '50%' }}
                                spacing={3}>
                                <SummaryComponent />
                                <Box>
                                    <Stack
                                        direction='column'
                                        sx={{ paddingTop: '16px', gap: '12px' }}>
                                        {orderUsedCards.map((value: defaultCard) => {
                                            return <value.cardComponent key={value.cardType} />
                                        })}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default Resume3
