import { Stack, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'

const ContactInfoComponent: React.FC = ({ sectionBgColor }: { sectionBgColor?: string }) => {
    const [isModalOpen, setModalOpen] = useState(false)
    const contactRef = useRef<HTMLDivElement | null>(null)
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const contactDetails = [
        {
            label: 'ADDRESS:',
            value: `${euContext.resume.personalInfo.address.street},\n${euContext.resume.personalInfo.address.city}, ${euContext.resume.personalInfo.address.state}`,
            name: 'personalInfo.address'
        },
        {
            label: 'PHONE:',
            value: euContext.resume.personalInfo.phone[0]?.phone || '',
            name: 'personalInfo.phone[0].phone'
        },
        {
            label: 'EMAIL:',
            value: euContext.resume.personalInfo.email,
            name: 'personalInfo.email'
        },
        {
            label: 'PORTFOLIO:',
            value: euContext.resume.personalInfo.portfolio,
            name: 'personalInfo.portfolio'
        }
    ]

    const editNames = contactDetails.map((detail) => detail.name)

    return (
        <>
            <div
                ref={contactRef}
                onClick={handleOpenModal}
                style={{ cursor: 'pointer' }}>
                <Stack
                    direction='column'
                    gap={2}>
                    {contactDetails.map((detail, index) => (
                        <Stack
                            key={index}
                            direction='row'>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'initial'}
                                textAlign={style?.BodyAlign ?? 'initial'}
                                color={style?.SectionFontColor ?? 'initial'}
                                letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                                fontWeight={style?.SectionFontWeight ?? 'initial'}
                                textTransform={style?.BodyFontCase ?? 'none'}
                                width='100%'
                                bgcolor={sectionBgColor}>
                                {detail.label}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'initial'}
                                textAlign={style?.BodyAlign ?? 'initial'}
                                color={style?.SectionFontColor ?? 'initial'}
                                letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                                fontWeight={style?.SectionFontWeight ?? 'initial'}
                                textTransform={style?.BodyFontCase ?? 'none'}
                                width='100%'
                                bgcolor={sectionBgColor}>
                                {detail.value}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={editNames}
                isAddress={true}
                hideTitle={true}
            />
        </>
    )
}

export default ContactInfoComponent
