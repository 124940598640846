import { Alert, Snackbar } from '@mui/material'
import { isAxiosError } from 'axios'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useLogout } from '../hooks/useAuth'
import { useTokenValidityAlert } from '../hooks/useTokens'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { TokenValidity } from '../utils/Util'

const TokenExpiryAlert: React.FC = () => {
    const expireDate = sessionStorage.getItem('expireDate') || ''
    const tokenValid: TokenValidity = useTokenValidityAlert(expireDate)
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const navigate = useNavigate()
    const { mutate: logoutMutate } = useLogout()

    if (tokenValid === TokenValidity.Expired) {
        logoutMutate(
            { email: euContext.user.email, subdomain: euContext.domain },
            {
                onSuccess: () => {
                    euContext.setUser({} as IUser)
                    euContext.setResume({} as IResume)
                },
                onError: (error) => {
                    if (isAxiosError(error) && error.response) {
                        console.error(error.response.data.message)
                    } else {
                        console.error('An unknown error occurred')
                    }
                }
            }
        )
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('expireDate')

        navigate('/login')
    }

    return (
        <Snackbar
            open={tokenValid === TokenValidity.ExpiringSoon}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={60000}>
            <Alert severity='warning'>Your session is about to expire. Please renew your token or re-login to continue.</Alert>
        </Snackbar>
    )
}

export default TokenExpiryAlert
