import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { tokensLink } from '../utils/Constants'
import { getTokenTokenValidity, TokenValidity } from '../utils/Util'

export interface ITokenResponse {
    accessToken: string
    user: IUser
    resume: IResume
}

const fetchTokens = async (): Promise<ITokenResponse> => {
    const response = await axios.get<ITokenResponse>(tokensLink)
    return response.data
}

export const useTokens = () => {
    return useQuery<ITokenResponse>({
        queryKey: ['tokens'],
        queryFn: fetchTokens
    })
}

export const useTokenValidityAlert = (expireDate: string, thresholdInMinutes: number = 5) => {
    const [tokenValidity, setTokenValidity] = useState<TokenValidity>(TokenValidity.Valid)

    useEffect(() => {
        const checkTokenExpiry = () => {
            setTokenValidity(getTokenTokenValidity(expireDate, thresholdInMinutes))
        }

        checkTokenExpiry()
        const intervalId = setInterval(checkTokenExpiry, 60000) // This is one minute

        return () => clearInterval(intervalId)
    }, [expireDate, thresholdInMinutes])

    return tokenValidity
}
