import DeleteIcon from '@mui/icons-material/Delete'
import { Autocomplete, Box, Button, IconButton, Stack, Tab, Tabs, TextField } from '@mui/material'
import React from 'react'
import { Degrees, IEducation } from '../../models/Education'

interface EducationEditModalComponentProps {
    educationList: IEducation[]
    setEducationList: React.Dispatch<React.SetStateAction<IEducation[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const EducationEditModalComponent: React.FC<EducationEditModalComponentProps> = ({ educationList, setEducationList, selectedTab, setSelectedTab }) => {
    const handleAddEducation = () => {
        const newEducation: IEducation = {
            degree: '',
            major: '',
            school: '',
            graduationMonth: 1,
            graduationYear: new Date().getFullYear(),
            seqNum: educationList.length
        }
        setEducationList([...educationList, newEducation])
        setSelectedTab(educationList.length)
    }

    const handleDeleteEducation = (index: number) => {
        const updatedList = educationList.filter((_, i) => i !== index)
        setEducationList(updatedList)
        setSelectedTab(Math.max(0, index - 1))
    }

    const handleEducationChange = (index: number, field: keyof IEducation, value: string | number) => {
        setEducationList((prevList) => prevList.map((education, i) => (i === index ? { ...education, [field]: value } : education)))
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}>
                {educationList.map((education, index) => (
                    <Tab
                        key={index}
                        label={education.school || 'Untitled'}
                    />
                ))}
            </Tabs>
            <Box sx={{ mt: 2, mb: 2 }}>
                <Stack
                    spacing={3}
                    direction='column'>
                    <Stack
                        spacing={3}
                        direction='row'>
                        <Autocomplete
                            options={Degrees}
                            getOptionLabel={(option) => option.label}
                            value={Degrees.find((degree) => degree.value === educationList[selectedTab]?.degree) || null}
                            onChange={(_, newValue) => {
                                handleEducationChange(selectedTab, 'degree', newValue ? newValue.value : '')
                            }}
                            onInputChange={(_, newInputValue) => {
                                const matchingDegree = Degrees.find((degree) => degree.label === newInputValue)
                                handleEducationChange(selectedTab, 'degree', matchingDegree ? matchingDegree.value : '')
                            }}
                            sx={{ flexGrow: 1 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Degree'
                                    variant='outlined'
                                    sx={{ flexGrow: 1 }}
                                />
                            )}
                        />
                        <TextField
                            label='Major'
                            variant='outlined'
                            value={educationList[selectedTab]?.major || ''}
                            onChange={(e) => handleEducationChange(selectedTab, 'major', e.target.value)}
                            sx={{ flexGrow: 1 }}
                        />
                    </Stack>
                    <Stack
                        spacing={3}
                        direction='row'>
                        <TextField
                            label='School'
                            variant='outlined'
                            value={educationList[selectedTab]?.school || ''}
                            onChange={(e) => handleEducationChange(selectedTab, 'school', e.target.value)}
                            sx={{ flexGrow: 1 }}
                        />
                        <TextField
                            label='Graduation Date'
                            type='month'
                            variant='outlined'
                            value={educationList[selectedTab] ? `${educationList[selectedTab].graduationYear}-${educationList[selectedTab].graduationMonth.toString().padStart(2, '0')}` : ''}
                            onChange={(e) => {
                                const [year, month] = e.target.value.split('-')
                                handleEducationChange(selectedTab, 'graduationYear', parseInt(year, 10))
                                handleEducationChange(selectedTab, 'graduationMonth', parseInt(month, 10))
                            }}
                            sx={{ flexGrow: 1 }}
                        />
                    </Stack>
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    mt={2}>
                    <IconButton
                        onClick={() => handleDeleteEducation(selectedTab)}
                        color='error'>
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleAddEducation}>
                        Add Education
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default EducationEditModalComponent
