import { Alert, Box, Button, Divider, FormControl, Input, InputLabel, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/named
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { isAxiosError } from 'axios'
// eslint-disable-next-line import/named
import { jwtDecode, JwtPayload } from 'jwt-decode'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useRegister } from '../hooks/useAuth'
import { useEnvironments } from '../hooks/useEnvironments'
import { type EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { registerGoogle, registerReturnInterface } from '../utils/DBUtils'
import LoadingState from '../views/LoadingState'

export const RegisterPage = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [emailValue, setEmailValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('')
    const [firstNameValue, setFirstNameValue] = useState('')
    const [lastNameValue, setLastNameValue] = useState('')

    const { mutate: registerMutate, isPending: registerLoading } = useRegister()
    const { data: environmentResponse, isLoading: environmentLoading } = useEnvironments()
    const navigate = useNavigate()

    const onRegisterClickedWrapper = (): void => {
        onRegisterClicked().catch((error) => {
            console.error(error)
        })
    }

    const handleLinkedInRegister = async () => {
        const params = 'scrollbars=no,resizable=no,status=no,locationbar=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100'

        const onMessage = (event: MessageEvent) => {
            if (event.data.user) euContext.setUser(event.data.user)
            if (event.data.resume) euContext.setResume(event.data.resume)

            if (event.data.user) {
                window.removeEventListener('message', onMessage)
                navigate('/')
            }
        }
        window.addEventListener('message', onMessage)

        window.open(environmentResponse?.linkedinOAuthURLRegister, '_linkedIn', params)
    }

    const handleGoogleRegister = async (codeResponse: CredentialResponse) => {
        if (environmentResponse && codeResponse.credential) {
            setErrorMessage('')
            try {
                const user: JwtPayload = jwtDecode(codeResponse.credential)

                const response: registerReturnInterface = await registerGoogle(user, euContext.domain)

                if (response?.error) {
                    setErrorMessage(response.error)
                    return
                }

                if (response?.user) {
                    euContext.setUser(response.user)
                }

                if (response?.resume) {
                    euContext.setResume(response.resume)
                }

                navigate('/')
            } catch (err) {
                setErrorMessage('An unknown error occurred during Google sign-up')
            }
        }
    }

    const onRegisterClicked = async (): Promise<void> => {
        try {
            registerMutate(
                { email: emailValue, password: passwordValue, firstName: firstNameValue, lastName: lastNameValue, subdomain: euContext.domain },
                {
                    onSuccess: (response) => {
                        euContext.setUser(response.user)
                        euContext.setResume(response.resume)
                        navigate('/')
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error.response) {
                            setErrorMessage(error.response.data.message)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                }
            )
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage('An unknown error occurred')
            }
        }
    }

    return (
        <>
            {environmentLoading && <LoadingState />}
            {!environmentLoading && (
                <Box
                    sx={{
                        margin: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography component='h5'>Sign Up</Typography>
                    <Stack
                        spacing={2}
                        width='100%'>
                        {errorMessage && (
                            <Alert
                                severity='error'
                                variant='filled'>
                                {errorMessage}
                            </Alert>
                        )}
                        <FormControl fullWidth>
                            <InputLabel htmlFor='firstName'>First Name</InputLabel>
                            <Input
                                id='firstName'
                                value={firstNameValue}
                                onChange={(e) => {
                                    setFirstNameValue(e.target.value)
                                }}
                                required
                                fullWidth
                                autoFocus
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='lastName'>Last Name</InputLabel>
                            <Input
                                id='lastName'
                                value={lastNameValue}
                                onChange={(e) => {
                                    setLastNameValue(e.target.value)
                                }}
                                required
                                fullWidth
                                autoFocus
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='email'>Email Address</InputLabel>
                            <Input
                                id='email'
                                type='email'
                                value={emailValue}
                                onChange={(e) => {
                                    setEmailValue(e.target.value)
                                }}
                                placeholder='someone@gmail.com'
                                required
                                fullWidth
                                autoComplete='email'
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='password'>Password</InputLabel>
                            <Input
                                id='password'
                                value={passwordValue}
                                onChange={(e) => {
                                    setPasswordValue(e.target.value)
                                }}
                                placeholder='password'
                                required
                                fullWidth
                                name='password'
                                type='password'
                                autoComplete='current-password'
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='confirmPassword'>Confirm Password</InputLabel>
                            <Input
                                id='confirmPassword'
                                value={confirmPasswordValue}
                                onChange={(e) => {
                                    setConfirmPasswordValue(e.target.value)
                                }}
                                required
                                error={passwordValue !== confirmPasswordValue}
                                fullWidth
                                name='confirmPassword'
                                type='password'
                                autoComplete='confirm-password'
                            />
                            {passwordValue !== confirmPasswordValue && <Typography color='error'>Passwords do not match</Typography>}
                        </FormControl>
                        <Divider />
                        <Button
                            variant='contained'
                            disabled={!emailValue || !passwordValue || passwordValue !== confirmPasswordValue || registerLoading}
                            onClick={onRegisterClickedWrapper}>
                            {registerLoading ? 'Registering...' : 'Register'}
                        </Button>
                        <GoogleLogin
                            text='signup_with'
                            onSuccess={handleGoogleRegister}
                            onError={() => console.log('error')}
                        />
                        <Button
                            variant='contained'
                            onClick={handleLinkedInRegister}>
                            Register With LinkedIn Account
                        </Button>
                    </Stack>
                </Box>
            )}
        </>
    )
}
