import { Box, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IProject } from '../../../models/Project'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { markdownToPlainText } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'

interface Props {
    sectionBgColor?: string
}

const ProjectsComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume } = euContext
    const projects: IProject[] = resume.projects.topics

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const handleOpenModal = (index: number) => {
        setCurrentIndex(index)
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                onClick={() => handleOpenModal(0)}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {resume.projects.label}
            </Typography>
            {projects.length === 0 ? (
                <Typography
                    onClick={() => handleOpenModal(0)}
                    fontFamily={style[style.BodyFontName]}
                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                    alignSelf={style?.BodyAlign ?? 'initial'}
                    textAlign={style?.BodyAlign ?? 'initial'}
                    width='100%'
                    color={style?.BodyFontColor ?? 'black'}
                    letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                    sx={{ textTransform: style?.BodyFontCase ?? 'none' }}>
                    No projects available. Click to add a new project.
                </Typography>
            ) : (
                projects.map((project, index) => (
                    <ListItem
                        key={index}
                        sx={{ width: '100%' }}
                        onClick={() => handleOpenModal(index)}>
                        <Box
                            width='100%'
                            rowGap={0}>
                            <Stack
                                direction='column'
                                spacing={0}
                                rowGap={0}
                                sx={{ flexGrow: 1 }}>
                                <Typography
                                    fontFamily={style[style.JobTitleFontName]}
                                    sx={{
                                        fontSize: `${style?.JobTitleFontSize ?? 5}px`,
                                        fontStyle: style?.JobTitleFontStyle ?? 'initial',
                                        alignSelf: style?.JobTitleAlign ?? 'end',
                                        fontWeight: style?.JobTitleFontWeight ?? 'initial',
                                        textAlign: style?.JobTitleAlign ?? 'initial',
                                        color: style?.JobTitleFontColor ?? 'black',
                                        letterSpacing: style?.JobTitleFontLetterSpacing ?? 1,
                                        textTransform: style?.JobTitleFontCase ?? 'none'
                                    }}>
                                    {project.topic}
                                </Typography>
                                <Typography
                                    fontFamily={style[style.JobTitleFontName]}
                                    dangerouslySetInnerHTML={{ __html: markdownToPlainText(project.description) || '' }}
                                    sx={{
                                        fontSize: `${style?.BodyFontSize ?? 5}px`,
                                        fontStyle: style?.BodyFontStyle ?? 'initial',
                                        alignSelf: style?.BodyAlign ?? 'end',
                                        fontWeight: style?.BodyFontWeight ?? 'initial',
                                        textAlign: style?.BodyAlign ?? 'initial',
                                        color: style?.BodyFontColor ?? 'black',
                                        letterSpacing: style?.BodyFontLetterSpacing ?? 1,
                                        textTransform: style?.BodyFontCase ?? 'none'
                                    }}
                                />
                            </Stack>
                        </Box>
                    </ListItem>
                ))
            )}
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    isProjects={true}
                />
            )}
        </Box>
    )
}

export default ProjectsComponent
