import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import axios from '../config/axiosConfig'

export const StripeStatus = () => {
    const [status, setStatus] = useState<string | null>(null)
    const [customerEmail, setCustomerEmail] = useState<string>('')

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const sessionId = urlParams.get('session_id')

        axios(`/api/session-status?session_id=${sessionId}`).then((res) => {
            setStatus(res.data.status)
            setCustomerEmail(res.data.customer_email)
        })
    }, [])

    if (status === 'open') {
        return <Navigate to='/checkout' />
    }

    if (status === 'complete') {
        return (
            <Typography>
                We appreciate your business! A confirmation email will be sent to {customerEmail}. If you have any questions, please email{' '}
                <a href='mailto:orders@employmentuniverse.com'>orders@employmentuniverse.com</a>.
            </Typography>
        )
    }

    return null
}
