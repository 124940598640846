// axiosConfig.ts
import axios from 'axios'
import { tokenRefreshLink } from '../utils/Constants'
import { SERVER_LOCATION_DEV, SERVER_LOCATION_PROD, SERVER_LOCATION_TEST, SERVER_PORT_DEV, SERVER_PORT_PROD, SERVER_PORT_TEST } from '../utils/ServerConstants'

const Locations: { [key: string]: string } = { development: SERVER_LOCATION_DEV, test: SERVER_LOCATION_TEST, production: SERVER_LOCATION_PROD }
const Ports: { [key: string]: number } = { development: SERVER_PORT_DEV, test: SERVER_PORT_TEST, production: SERVER_PORT_PROD }
const URL_PREFIX = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http' : 'https'
const ENV_TYPE: string = process.env.NODE_ENV ?? 'development'
const SERVER_LOCATION = Locations[ENV_TYPE] ?? SERVER_LOCATION_DEV
const SERVER_PORT = Ports[ENV_TYPE] ?? SERVER_PORT_DEV
console.log('node_env', process.env.NODE_ENV)
const instance = axios.create({
    baseURL: `${URL_PREFIX}://${SERVER_LOCATION}:${SERVER_PORT}`, // Set your base URL here
    timeout: 5000 // Set a timeout for requests (optional)
})

console.log('baseURL', instance.defaults.baseURL)
instance.defaults.withCredentials = true

instance.interceptors.request.use(
    (config) => {
        // Get the accessToken from your authentication state
        const accessToken: string | null = sessionStorage.getItem('accessToken')
        // Set the accessToken in the request header
        if (accessToken && accessToken.length > 0) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
    },
    (error) => {
        console.error('Error in request', error)
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        const { accessToken, expireDate } = response.data

        if (expireDate) {
            sessionStorage.setItem('expireDate', expireDate)
        }

        if (accessToken) {
            sessionStorage.setItem('accessToken', accessToken)
        }

        return response
    },
    async (error) => {
        const originalRequest = error.config

        // Check if the error is due to an unauthorized request
        if (error.response?.status === 400 && !originalRequest._retry) {
            originalRequest._retry = true // Prevent infinite loop
            try {
                // Attempt to refresh the access token
                const response = await instance.post(tokenRefreshLink)
                const newAccessToken = response.data.accessToken
                sessionStorage.setItem('accessToken', newAccessToken) // Update your state with the new access token
                sessionStorage.setItem('expireDate', response.data.expireDate)

                // Update the original request's Authorization header
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

                // Retry the original request with the new token
                return instance(originalRequest)
            } catch (tokenRefreshError) {
                // Handle token refresh failure (e.g., logout the user)
                console.error('Token refresh failed', tokenRefreshError)
                return Promise.reject(tokenRefreshError)
            }
        }

        // Handle other errors
        return Promise.reject(error)
    }
)

export default instance
