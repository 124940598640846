import { Box, Grid2, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'

type Props = {
    sectionBgColor?: string
}

const TalentsComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const talents = euContext.resume.talents.talent

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                onClick={() => openEditModal(0)}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.talents.label || 'Talents'}
            </Typography>
            <Box
                width='100%'
                rowGap={0}>
                {talents.length === 0 ? (
                    <Box
                        onClick={() => openEditModal(0)}
                        sx={{ cursor: 'pointer', padding: 2, backgroundColor: 'lightgrey', borderRadius: 4, textAlign: 'center' }}>
                        <Typography
                            fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontWeight={style?.BodyFontWeight ?? 'bold'}
                            color={style?.BodyFontColor ?? 'black'}>
                            No talents found. Click to add talents.
                        </Typography>
                    </Box>
                ) : (
                    talents.map((talent, index) => (
                        <ListItem
                            key={index}
                            sx={{ width: '100%' }}
                            onClick={() => openEditModal(index)}>
                            <Box
                                width='100%'
                                rowGap={0}>
                                <Stack
                                    direction='column'
                                    spacing={0}
                                    rowGap={0}
                                    sx={{ flexGrow: 1 }}>
                                    <Typography
                                        fontFamily={style[style.JobTitleFontName]}
                                        fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                                        fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                                        alignSelf={style?.JobTitleAlign ?? 'end'}
                                        fontWeight={style?.JobTitleFontWeight ?? 'initial'}
                                        sx={{ color: style?.BodyFontColor ?? 'black', letterSpacing: style?.BodyFontLetterSpacing ?? 1 }}>
                                        {talent.production}
                                    </Typography>
                                    <Grid2
                                        container
                                        spacing={1}
                                        direction='row'
                                        justifyContent='space-between'
                                        sx={{ flexGrow: 1 }}>
                                        <Grid2 size={6}>
                                            <Typography
                                                fontFamily={style[style.CompanyFontName]}
                                                fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                                fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                                alignSelf={style?.CompanyAlign ?? 'end'}
                                                fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                                sx={{
                                                    textTransform: style?.CompanyFontCase ?? 'initial',
                                                    color: style?.CompanyFontColor ?? 'black',
                                                    letterSpacing: style?.CompanyFontLetterSpacing ?? 1
                                                }}>
                                                {talent.producer}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Typography
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        alignSelf={style?.BodyAlign ?? 'end'}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                                        sx={{ color: style?.BodyFontColor ?? 'black', letterSpacing: style?.BodyFontLetterSpacing ?? 1 }}>
                                        {talent.role}
                                    </Typography>
                                </Stack>
                            </Box>
                        </ListItem>
                    ))
                )}
            </Box>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isTalents={true}
                />
            )}
        </Box>
    )
}

export default TalentsComponent
