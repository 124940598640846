import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import { ITalent } from '../../models/Talent'

interface TalentEditModalComponentProps {
    talentList: ITalent[]
    setTalentList: React.Dispatch<React.SetStateAction<ITalent[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const TalentEditModalComponent: React.FC<TalentEditModalComponentProps> = ({ talentList, setTalentList, selectedTab, setSelectedTab }) => {
    const handleTalentChange = (index: number, field: keyof ITalent, value: string) => {
        setTalentList((prevTalents) => prevTalents.map((talent, i) => (i === index ? { ...talent, [field]: value } : talent)))
    }

    const handleAddTalent = () => {
        const newTalent: ITalent = {
            production: '',
            producer: '',
            role: '',
            seqNum: talentList.length
        }
        setTalentList([...talentList, newTalent])
        setSelectedTab(talentList.length)
    }

    const handleDeleteTalent = (index: number) => {
        const updatedTalents = talentList.filter((_, i) => i !== index)
        setTalentList(updatedTalents)
        setSelectedTab((prevTab) => Math.max(0, prevTab - 1))
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                aria-label='talent tabs'>
                {talentList.map((talent, index) => (
                    <Tab
                        key={index}
                        label={talent.production || 'Untitled'}
                    />
                ))}
            </Tabs>
            {talentList[selectedTab] && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Stack
                        spacing={3}
                        direction='column'>
                        <TextField
                            label='Production'
                            variant='outlined'
                            value={talentList[selectedTab]?.production || ''}
                            onChange={(e) => handleTalentChange(selectedTab, 'production', e.target.value)}
                        />
                        <TextField
                            label='Producer'
                            variant='outlined'
                            value={talentList[selectedTab]?.producer || ''}
                            onChange={(e) => handleTalentChange(selectedTab, 'producer', e.target.value)}
                        />
                        <TextField
                            label='Role'
                            variant='outlined'
                            value={talentList[selectedTab]?.role || ''}
                            onChange={(e) => handleTalentChange(selectedTab, 'role', e.target.value)}
                        />
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                        mt={2}>
                        <IconButton
                            onClick={() => handleDeleteTalent(selectedTab)}
                            color='error'>
                            <DeleteIcon />
                        </IconButton>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleAddTalent}>
                            Add Talent
                        </Button>
                    </Stack>
                </Box>
            )}
            {!talentList[selectedTab] && (
                <Box>
                    <Typography>No talents found. Add one to get started!</Typography>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                        mt={2}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleAddTalent}>
                            Add Talent
                        </Button>
                    </Stack>
                </Box>
            )}
        </Box>
    )
}

export default TalentEditModalComponent
