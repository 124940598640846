import { Avatar, Button, Skeleton, Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { updatePictureResume } from '../../../utils/DBUtils'

interface PictureComponentProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picMB?: number
    picML?: number
    picMR?: number
}

const PictureComponent = ({ picWidth = 200, picHeight = 200, picAlign = 'center', picMT = 0, picMB = 0, picML = 0, picMR = 0 }: PictureComponentProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [selectedImage, setSelectedImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const [hover, setHover] = useState<boolean>(false)

    const pictureRef = useRef<HTMLDivElement | null>(null)
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (euContext.resume.picture) {
            const filePicture: string = euContext.resume.picture
            setSelectedImage(filePicture)
        } else {
            setSelectedImage('empty')
        }
        setLoading(false)
    }, [euContext.resume])

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const handleChange = async (evt: FormEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const pictureFile = evt.currentTarget.files ? evt.currentTarget.files[0] : null
        if (pictureFile) {
            setLoading(true)
            const pictureReader = new FileReader()
            pictureReader.readAsDataURL(pictureFile)

            pictureReader.onload = () => {
                const pictureResult: string = pictureReader.result as string
                if (pictureResult) {
                    updatePictureResume(euContext.resume._id, pictureResult)
                    setSelectedImage(pictureResult)
                    setLoading(false)
                }
            }

            pictureReader.onerror = () => {
                console.error('Picture error: ' + pictureReader.error)
                setLoading(false)
            }
        }
    }

    return (
        <div
            ref={pictureRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', width: picWidth, height: picHeight }}>
            <Stack spacing={0}>
                {loading ? (
                    <Skeleton
                        variant='circular'
                        width={picWidth}
                        height={picHeight}
                        sx={{ alignSelf: picAlign, mt: picMT, mb: picMB, ml: picML, mr: picMR }}
                    />
                ) : (
                    selectedImage &&
                    style?.ShowGraphic && (
                        <Avatar
                            src={selectedImage}
                            sx={{ width: picWidth, height: picHeight, alignSelf: picAlign, verticalAlign: 'middle', mt: picMT, mb: picMB, ml: picML, mr: picMR }}
                            onClick={handleImageClick}
                        />
                    )
                )}
                <input
                    type='file'
                    accept='image/*'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
                {hover && (
                    <Button
                        onClick={handleImageClick}
                        sx={{
                            position: 'absolute',
                            top: '60%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'black',
                            color: 'white',
                            border: '1px solid white',
                            cursor: 'pointer',
                            fontSize: 'clamp(12px, 2vw, 16px)',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: 'black',
                                borderColor: 'white'
                            }
                        }}>
                        Upload Image
                    </Button>
                )}
            </Stack>
        </div>
    )
}

export default PictureComponent
