import { type SearchForSuggestionsResult } from '@aws-sdk/client-location'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getLocation, getPlace } from '../../services/LocationService'
import ModalTextField from '../forms/ModalTextField'

interface PersonalInfoEditModalComponentProps {
    name: string
    label?: string
}

const PersonalInfoEditModalComponent: React.FC<PersonalInfoEditModalComponentProps> = ({ name, label }) => {
    const { setValue } = useFormContext()
    const [locationOptions, setLocationOptions] = useState<SearchForSuggestionsResult[]>([])
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)

    const handleLocationSuggestions = async (inputValue: string) => {
        setLoadingSuggestions(true)
        const suggestions = await getLocation(inputValue)
        if (suggestions) {
            setLocationOptions(suggestions.map((suggestion) => suggestion))
        }
        setLoadingSuggestions(false)
    }

    const handleSelectLocation = async (placeId: string) => {
        setValue('personalInfo.address.street', '')

        if (placeId) {
            const place = await getPlace(placeId)

            if (place) {
                const street = `${place.AddressNumber ?? ''} ${place.Street ?? ''}`
                setValue('personalInfo.address.street', street ?? '')
                setValue('personalInfo.address.city', place.Municipality ?? '')
                setValue('personalInfo.address.state', place.Region ?? '')
                setValue('personalInfo.address.zip', place.PostalCode ?? '')
            }
        }
    }

    return (
        <ModalTextField
            key={name}
            name={name}
            label={label || (name === 'personalInfo.address.street' ? 'Street Address' : name)}
            isAddressField={name === 'personalInfo.address.street'}
            onLocationSelect={handleSelectLocation}
            onLocationSuggestions={handleLocationSuggestions}
            locationOptions={locationOptions}
            loadingSuggestions={loadingSuggestions}
        />
    )
}

export default PersonalInfoEditModalComponent
