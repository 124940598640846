import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import { IProject } from '../../models/Project'
import QuillEditor from '../forms/QuillEditor'

interface ProjectEditModalComponentProps {
    projectList: IProject[]
    setProjects: React.Dispatch<React.SetStateAction<IProject[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const ProjectEditModalComponent: React.FC<ProjectEditModalComponentProps> = ({ projectList, setProjects, selectedTab, setSelectedTab }) => {
    const handleAddProject = () => {
        const newProject: IProject = { topic: '', description: '', seqNum: projectList.length }
        setProjects([...projectList, newProject])
        setSelectedTab(projectList.length)
    }

    const handleDeleteProject = (index: number) => {
        const updatedProjects = projectList.filter((_, i) => i !== index)
        setProjects(updatedProjects)
        setSelectedTab(Math.max(0, selectedTab - 1))
    }

    const handleProjectChange = (index: number, field: keyof IProject, value: string | number) => {
        const updatedProjects = projectList.map((project, i) => (i === index ? { ...project, [field]: value } : project))
        setProjects(updatedProjects)
    }

    const handleProjectQuillChange = (index: number, value: string) => {
        const updatedProjects = [...projectList]
        updatedProjects[index].description = value
        setProjects(updatedProjects)
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                aria-label='project tabs'>
                {projectList.map((project, index) => (
                    <Tab
                        key={index}
                        label={project.topic || 'Untitled'}
                    />
                ))}
            </Tabs>
            <Box sx={{ mt: 2, mb: 2 }}>
                <Stack
                    spacing={3}
                    direction='column'>
                    <TextField
                        label='Topic'
                        variant='outlined'
                        value={projectList[selectedTab]?.topic || ''}
                        onChange={(e) => handleProjectChange(selectedTab, 'topic', e.target.value)}
                    />
                    <Typography variant='h6'>Description</Typography>
                    <QuillEditor
                        value={projectList[selectedTab]?.description || ''}
                        onChange={(value) => handleProjectQuillChange(selectedTab, value)}
                    />
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    mt={2}>
                    <IconButton
                        onClick={() => handleDeleteProject(selectedTab)}
                        color='error'>
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleAddProject}>
                        Add Project
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default ProjectEditModalComponent
