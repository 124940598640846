import { Box, Grid2, ListItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'

type Props = {
    sectionBgColor?: string
}

const ReferencesComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const references = euContext.resume.references

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Typography
                onClick={() => openEditModal(0)}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none', cursor: 'pointer' }}>
                {references.label}
            </Typography>
            <Box
                width='100%'
                rowGap={0}>
                {references.reference.length === 0 ? (
                    <Box
                        onClick={() => openEditModal(0)}
                        sx={{ cursor: 'pointer', padding: 2, backgroundColor: 'lightgrey', borderRadius: 4, textAlign: 'center' }}>
                        <Typography
                            fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontWeight={style?.BodyFontWeight ?? 'bold'}
                            color={style?.BodyFontColor ?? 'black'}>
                            No references found
                        </Typography>
                    </Box>
                ) : (
                    references.reference.map((reference, index) => (
                        <ListItem
                            key={index}
                            sx={{ width: '100%' }}>
                            <Box
                                width='100%'
                                onClick={() => openEditModal(index)}
                                rowGap={0}>
                                <Grid2
                                    container
                                    spacing={1}
                                    direction='row'>
                                    <Grid2 size='auto'>
                                        <Typography
                                            sx={{
                                                textTransform: style?.BodyFontCase ?? 'initial',
                                                alignItems: style?.BodyAlign,
                                                color: style?.BodyFontColor ?? 'black',
                                                letterSpacing: style?.BodyFontLetterSpacing ?? 1
                                            }}
                                            fontFamily={style?.BodyFontName ?? 'initial'}
                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                            alignSelf={style?.BodyAlign ?? 'end'}
                                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                            {reference.name}
                                        </Typography>
                                    </Grid2>
                                    <Grid2 size='auto'>
                                        <Typography
                                            sx={{
                                                textTransform: style?.BodyFontCase ?? 'initial',
                                                alignItems: style?.BodyAlign,
                                                color: style?.BodyFontColor ?? 'black',
                                                letterSpacing: style?.BodyFontLetterSpacing ?? 1
                                            }}
                                            fontFamily={style?.BodyFontName ?? 'initial'}
                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                            alignSelf={style?.BodyAlign ?? 'end'}
                                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                            {reference.email}
                                        </Typography>
                                    </Grid2>
                                    <Grid2 size='auto'>
                                        <Typography
                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                            alignSelf={style?.BodyAlign ?? 'end'}
                                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                            {reference.phone}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </ListItem>
                    ))
                )}
            </Box>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isReferences={true}
                />
            )}
        </Box>
    )
}

export default ReferencesComponent
