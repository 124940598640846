import EditIcon from '@mui/icons-material/Edit'
import { Box, Divider, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface EditModalTitleProps {
    label: string
    variant?: 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2'
    setNewLabel: (label: string) => void
}

const EditModalTitle: React.FC<EditModalTitleProps> = ({ label, variant = 'h4', setNewLabel }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [currentLabel, setCurrentLabel] = useState(label)

    useEffect(() => {
        setCurrentLabel(label)
    }, [label])

    const handleEditClick = () => {
        setIsEditing(true)
    }

    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentLabel(event.target.value)
        setNewLabel(event.target.value)
    }

    const handleBlur = () => {
        setIsEditing(false)
    }

    return (
        <>
            {isEditing ? (
                <TextField
                    value={currentLabel}
                    onChange={handleLabelChange}
                    onBlur={handleBlur}
                    autoFocus
                    variant='standard'
                    sx={{ fontSize: `${variant}.fontSize`, fontWeight: 'bold', mb: 2, width: '100%' }}
                />
            ) : (
                <Typography
                    variant={variant}
                    component='div'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        width: '100%',
                        overflow: 'hidden'
                    }}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        {currentLabel}
                    </Box>
                    <IconButton
                        onClick={handleEditClick}
                        size='small'
                        aria-label='edit'
                        sx={{ ml: 1 }}>
                        <EditIcon fontSize='small' />
                    </IconButton>
                </Typography>
            )}
            <Divider sx={{ paddingTop: 1 }} />
        </>
    )
}

export default EditModalTitle
