import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Stack, Tab, Tabs, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { IPublication } from '../../models/Publication'

interface PublicationEditModalComponentProps {
    publicationList: IPublication[]
    setPublicationList: React.Dispatch<React.SetStateAction<IPublication[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const PublicationEditModalComponent: React.FC<PublicationEditModalComponentProps> = ({ publicationList, setPublicationList, selectedTab, setSelectedTab }) => {
    const handleAddPublication = () => {
        const newPublication: IPublication = {
            title: '',
            publisher: '',
            publications: '',
            pubYear: new Date().getFullYear(),
            pubMonth: 1,
            pages: 0,
            description: '',
            seqNum: publicationList.length
        }
        setPublicationList([...publicationList, newPublication])
        setSelectedTab(publicationList.length)
    }

    const handleDeletePublication = (index: number) => {
        const updatedList = publicationList.filter((_, i) => i !== index)
        setPublicationList(updatedList)
        setSelectedTab(Math.max(0, index - 1))
    }

    const handlePublicationChange = (index: number, field: keyof IPublication, value: string | number) => {
        setPublicationList((prevList) => prevList.map((publication, i) => (i === index ? { ...publication, [field]: value } : publication)))
    }

    useEffect(() => {
        if (publicationList.length === 0) {
            handleAddPublication()
        }
    }, [publicationList])

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                aria-label='publication tabs'>
                {publicationList.map((publication, index) => (
                    <Tab
                        key={index}
                        label={publication.title || 'Untitled'}
                    />
                ))}
            </Tabs>
            <Box sx={{ mt: 2, mb: 2 }}>
                <Stack
                    spacing={3}
                    direction='column'>
                    <TextField
                        label='Title'
                        variant='outlined'
                        value={publicationList[selectedTab]?.title || ''}
                        onChange={(e) => handlePublicationChange(selectedTab, 'title', e.target.value)}
                    />
                    <TextField
                        label='Publisher'
                        variant='outlined'
                        value={publicationList[selectedTab]?.publisher || ''}
                        onChange={(e) => handlePublicationChange(selectedTab, 'publisher', e.target.value)}
                    />
                    <TextField
                        label='Publication Date'
                        type='month'
                        variant='outlined'
                        value={publicationList[selectedTab] ? `${publicationList[selectedTab].pubYear}-${String(publicationList[selectedTab].pubMonth).padStart(2, '0')}` : ''}
                        onChange={(e) => {
                            const [year, month] = e.target.value.split('-')
                            handlePublicationChange(selectedTab, 'pubYear', parseInt(year, 10))
                            handlePublicationChange(selectedTab, 'pubMonth', parseInt(month, 10))
                        }}
                    />
                    <TextField
                        label='Pages'
                        variant='outlined'
                        value={publicationList[selectedTab]?.pages || ''}
                        onChange={(e) => handlePublicationChange(selectedTab, 'pages', parseInt(e.target.value, 10))}
                    />
                    <TextField
                        label='Description'
                        variant='outlined'
                        multiline
                        rows={4}
                        value={publicationList[selectedTab]?.description || ''}
                        onChange={(e) => handlePublicationChange(selectedTab, 'description', e.target.value)}
                    />
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    mt={2}>
                    <IconButton
                        onClick={() => handleDeletePublication(selectedTab)}
                        color='error'>
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleAddPublication}>
                        Add Publication
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default PublicationEditModalComponent
