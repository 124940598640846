import { Box, Button, Skeleton, Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { updatePictureResume } from '../../../utils/DBUtils'

interface PictureDodecagonProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picML?: number
    picMR?: number
}

const PictureDodecagon = ({ picWidth = 200, picHeight = 200, picAlign = 'center', picMT = 0, picML = 0, picMR = 0 }: PictureDodecagonProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [selectedImage, setSelectedImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const [hover, setHover] = useState<boolean>(false)

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (euContext.resume.picture) {
            const filePicture: string = euContext.resume.picture
            setSelectedImage(filePicture)
        } else {
            setSelectedImage('empty')
        }
        setLoading(false)
    }, [euContext.resume])

    const handleChange = async (evt: FormEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const pictureFile = evt.currentTarget.files ? evt.currentTarget.files[0] : null
        if (pictureFile) {
            setLoading(true)
            const pictureReader = new FileReader()
            pictureReader.readAsDataURL(pictureFile)

            pictureReader.onload = () => {
                const pictureResult: string = pictureReader.result as string
                if (pictureResult) {
                    updatePictureResume(euContext.resume._id, pictureResult)
                    setSelectedImage(pictureResult)
                    setLoading(false)
                }
            }

            pictureReader.onerror = () => {
                console.error('Picture error: ' + pictureReader.error)
                setLoading(false)
            }
        }
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', width: picWidth, height: picHeight }}>
            <Stack
                spacing={0}
                sx={{ alignItems: picAlign, cursor: 'pointer' }}
                onClick={handleImageClick}>
                {loading ? (
                    <Skeleton
                        variant='circular'
                        width={picWidth}
                        height={picHeight}
                        sx={{ mt: picMT, ml: picML, mr: picMR }}
                    />
                ) : (
                    <Box
                        sx={{
                            display: 'inline-block',
                            width: picWidth,
                            height: picHeight,
                            position: 'relative',
                            overflow: 'hidden',
                            transform: 'rotate(60deg)',
                            marginRight: picMR,
                            marginLeft: picML,
                            marginTop: picMT
                        }}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                position: 'absolute',
                                overflow: 'hidden',
                                transform: 'rotate(-120deg)'
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    position: 'absolute',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundImage: `url(${selectedImage})`,
                                    transform: 'rotate(60deg)'
                                }}
                            />
                        </Box>
                    </Box>
                )}
                <input
                    type='file'
                    accept='image/*'
                    ref={fileInputRef}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </Stack>
            {hover && (
                <Button
                    onClick={handleImageClick}
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        bottom: '-20%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'black',
                        color: 'white',
                        border: '1px solid white',
                        cursor: 'pointer',
                        fontSize: 'clamp(12px, 2vw, 16px)',
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'black',
                            borderColor: 'white'
                        }
                    }}>
                    Upload Image
                </Button>
            )}
        </div>
    )
}

export default PictureDodecagon
