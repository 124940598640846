import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Stack, Tab, Tabs, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { IReference } from '../../models/Reference'

interface ReferencesEditModalComponentProps {
    referenceList: IReference[]
    setReferenceList: React.Dispatch<React.SetStateAction<IReference[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const ReferencesEditModalComponent: React.FC<ReferencesEditModalComponentProps> = ({ referenceList, setReferenceList, selectedTab, setSelectedTab }) => {
    const handleAddReference = () => {
        const newReference: IReference = {
            name: '',
            email: '',
            phone: 0,
            seqNum: referenceList.length
        }
        setReferenceList([...referenceList, newReference])
        setSelectedTab(referenceList.length)
    }

    const handleDeleteReference = (index: number) => {
        const updatedList = referenceList.filter((_, i) => i !== index)
        setReferenceList(updatedList)
        setSelectedTab(Math.max(0, index - 1))
    }

    const handleReferenceChange = (index: number, field: keyof IReference, value: string) => {
        setReferenceList((prevList) => prevList.map((reference, i) => (i === index ? { ...reference, [field]: value } : reference)))
    }

    useEffect(() => {
        if (referenceList.length === 0) {
            handleAddReference()
        }
    }, [referenceList])

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                aria-label='reference tabs'>
                {referenceList.map((reference, index) => (
                    <Tab
                        key={index}
                        label={reference.name || 'Untitled'}
                    />
                ))}
            </Tabs>
            <Box sx={{ mt: 2, mb: 2 }}>
                <Stack
                    spacing={3}
                    direction='column'>
                    <TextField
                        label='Name'
                        variant='outlined'
                        value={referenceList[selectedTab]?.name || ''}
                        onChange={(e) => handleReferenceChange(selectedTab, 'name', e.target.value)}
                    />
                    <TextField
                        label='Email'
                        variant='outlined'
                        value={referenceList[selectedTab]?.email || ''}
                        onChange={(e) => handleReferenceChange(selectedTab, 'email', e.target.value)}
                    />
                    <TextField
                        label='Phone'
                        variant='outlined'
                        value={referenceList[selectedTab]?.phone || ''}
                        onChange={(e) => handleReferenceChange(selectedTab, 'phone', e.target.value)}
                    />
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    mt={2}>
                    <IconButton
                        onClick={() => handleDeleteReference(selectedTab)}
                        color='error'>
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleAddReference}>
                        Add Reference
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default ReferencesEditModalComponent
